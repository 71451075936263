/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*Cover photo of two reference monitors. A funny joke, as you will undoubtedly agree.*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    a: "a",
    br: "br",
    em: "em",
    h2: "h2",
    ul: "ul",
    li: "li",
    pre: "pre",
    h3: "h3",
    strong: "strong"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: Refs are simple objects of the form ", React.createElement(_components.code, null, "{ current: //something }"), ". In function components, pick ", React.createElement(_components.code, null, "useRef"), ", otherwise, pick ", React.createElement(_components.code, null, "createRef"), ".")), "\n", React.createElement(_components.p, null, "In React, a ref is an object with a mutable ", React.createElement(_components.code, null, "current"), " property.\nThat’s it. You can view the ", React.createElement(_components.a, {
    href: "#implementations"
  }, "implementations"), " below but they boil down to this: ", React.createElement(_components.code, null, "ref = { current: //something }"), React.createElement(_components.br), "\n", "That ", React.createElement(_components.code, null, "current"), " property is often set to hold a ", React.createElement(_components.em, null, "reference"), " to a DOM Element, like an ", React.createElement(_components.code, null, "<audio>"), "tag"), "\n", React.createElement(_components.p, null, "Getting a reference to an element allows you to directly give it commands.\nThis is useful for controlling media playback, managing focus and more."), "\n", React.createElement(_components.p, null, "It might be tempting to do this everywhere, but ", React.createElement(_components.a, {
    href: "https://reactjs.org/docs/refs-and-the-dom.html#dont-overuse-refs"
  }, "resist"), " that temptation."), "\n", React.createElement(_components.h2, {
    id: "basic-usage"
  }, "Basic usage"), "\n", React.createElement(_components.p, null, "The goal is to get a reference to an ", React.createElement(_components.code, null, "<audio>"), "tag.\nThis will allow you to call ", React.createElement(_components.code, null, ".play()"), " and ", React.createElement(_components.code, null, ".pause()"), " on that element."), "\n", React.createElement(_components.p, null, "There are quite a few ways of achieving the same goal.", React.createElement(_components.br), "\n", "The steps are always the same however."), "\n", React.createElement(_components.p, null, "Several examples can be viewed in this ", React.createElement(_components.a, {
    href: "https://codesandbox.io/s/4jzlzr7937"
  }, "codesandbox demo")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "Code shown below: using ", React.createElement(_components.code, null, "createRef"), " in a class based component with a constructor.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Create the ref object"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// inside the constructor\nthis.audioRef = React.createRef();\n// this results in: this.audioRef = { current: null }\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Attach a reference to a DOM Element to that ref object"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// inside the render() method\n<audio ref={this.audioRef} />\n// this results in: this.audioRef = { current: <audio /> }\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use it"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "handlePlay = () => {\n  this.audioRef.play();\n};\n")), "\n", React.createElement(_components.h2, {
    id: "other-usecases"
  }, "Other usecases"), "\n", React.createElement(_components.h3, {
    id: "referring-to-react-components"
  }, "Referring to React Components"), "\n", React.createElement(_components.p, null, "The examples above showed how to get a reference to a DOM Element.\nThat’s not the only thing refs are good for. They can also point to a React Component object."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://codesandbox.io/s/9jxqj20wqo?expanddevtools=1&fontsize=14&module=%2Fsrc%2FParentComponent.js&moduleview=1"
  }, "Codesandbox demo")), "\n", React.createElement(_components.p, null, "Important note: you can ", React.createElement(_components.strong, null, "only"), " reference ", React.createElement(_components.strong, null, "class based"), " components this way"), "\n", React.createElement(_components.h3, {
    id: "referring-to-a-dom-element-inside-a-react-component"
  }, "Referring to a DOM Element inside a React Component"), "\n", React.createElement(_components.p, null, "What about when you made yourself a fancy ", React.createElement(_components.code, null, "Input"), " component and want a ref to point directly at the ", React.createElement(_components.code, null, "<input>"), "tag so you can call ", React.createElement(_components.code, null, "inputRef.current.focus()"), "?"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Input ref={inputRef} />\n")), "\n", React.createElement(_components.p, null, "There is a problem! It either points to the React Component object if your ", React.createElement(_components.code, null, "Input"), " is a class component, or it throws a big fat error if your ", React.createElement(_components.code, null, "Input"), " is a function component."), "\n", React.createElement(_components.p, null, "That ", React.createElement(_components.em, null, "big fat error"), " is really helpful:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "Warning: Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()?\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "React.forwardRef"), " receives a function that gets called with the props and the ref your component received. It returns what your component would normally return. There, you can attach that ref to the specific DOM Element you want."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "// in the file you want to use the ref\nimport Input from \"./Input\";\n\nfunction App() {\n  const inputRef = React.useRef();\n\n  // ...\n\n  return (\n    <div>\n      <Input />\n      {/* attach the ref to our Input component */}\n      <Input ref={inputRef} />\n      <Input />\n    </div>\n  );\n}\n\n// in your Input.js file\nexport default React.forwardRef((props, ref) => {\n  return (\n    // ...\n    // forward the ref our Input component received to the HTML <input/>tag\n    <input ref={ref} placeholder={props.placeholder} />\n    // ...\n  );\n});\n")), "\n", React.createElement(_components.p, null, "This ", React.createElement(_components.a, {
    href: "https://codesandbox.io/s/pyqq0o9mk7"
  }, "Codesandbox demo"), " shows three ", React.createElement(_components.code, null, "Input"), " components.\nOne ", React.createElement(_components.code, null, "<input/>tag"), " is focussed through a ref a component received and forwarded using ", React.createElement(_components.code, null, "React.forwardRef"), "."), "\n", React.createElement(_components.p, null, "Having to wrap your function component in ", React.createElement(_components.code, null, "React.forwardRef"), " can become quite tedious.\nIn the future, this usage of ", React.createElement(_components.code, null, "forwardRef"), " might become ", React.createElement(_components.a, {
    href: "https://twitter.com/dan_abramov/status/1109512531209584640"
  }, "default behaviour")), "\n", React.createElement(_components.h2, {
    id: "createref-vs-useref"
  }, "createRef vs. useRef"), "\n", React.createElement(_components.p, null, "While ", React.createElement(_components.code, null, "createRef"), " and ", React.createElement(_components.code, null, "useRef"), " are ", React.createElement(_components.strong, null, "very"), " similar. There are some subtle differences."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "useRef"), " is a so called ", React.createElement(_components.a, {
    href: "https://reactjs.org/docs/hooks-intro.html"
  }, "hook"), " and is only available in function based components."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// in a function component\nconst myRef = React.useRef(\"my initial value\");\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "useRef"), " creates an object ", React.createElement(_components.code, null, "{ current : initialValue }"), " and returns it the first time it gets called. If that object already exists, ", React.createElement(_components.code, null, "useRef"), " will return that existing object instead."), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "createRef"), " will create an object ", React.createElement(_components.code, null, "{ current : null }"), " each time it is called."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// in the constructor of a class based component\nthis.myRef = React.createRef();\n// even in a function based component\nconst myRef = React.createRef();\n")), "\n", React.createElement(_components.h3, {
    id: "instance-variables"
  }, "Instance variables"), "\n", React.createElement(_components.p, null, "In class based components, if you want a value that can change but doesn’t trigger a render cycle. An instance variable is what you want. eg: ", React.createElement(_components.code, null, "this.palindrome = 'racecar'"), " and later ", React.createElement(_components.code, null, "this.palindrome='pullup'")), "\n", React.createElement(_components.p, null, "In function components, ", React.createElement(_components.code, null, "useRef"), " can fill that role!", React.createElement(_components.br), "\n", "Since ", React.createElement(_components.code, null, "useRef(initialValue)"), " returns an object with a mutable ", React.createElement(_components.code, null, "current"), " property.\nThere is nothing stopping us from using it like an instance variable."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const palindrome = useRef(\"racecar\");\n// const palindrome = { current: 'racecar' }\n// ...\npalindrome.current = \"pullup\";\n// const palindrome = { current: 'pullup' }\n")), "\n", React.createElement(_components.p, null, "This ", React.createElement(_components.a, {
    href: "https://codesandbox.io/s/vmwxjnv433"
  }, "codesandbox"), " uses ", React.createElement(_components.code, null, "useRef"), " to point to the ", React.createElement(_components.code, null, "<form>"), " DOM element ", React.createElement(_components.strong, null, "and"), " to an internal count variable."), "\n", React.createElement(_components.h2, {
    id: "implementations"
  }, "Implementations"), "\n", React.createElement(_components.p, null, "The implementation of both ", React.createElement(_components.code, null, "createRef"), " and ", React.createElement(_components.code, null, "useRef"), " in the official ", React.createElement(_components.a, {
    href: "https://github.com/facebook/react"
  }, "React codebase"), " is surprisingly simple."), "\n", React.createElement(_components.h3, {
    id: "createref"
  }, "createRef"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "export function createRef() {\n  const refObject = {\n    current: null,\n  };\n  if (__DEV__) {\n    Object.seal(refObject);\n  }\n  return refObject;\n}\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://github.com/facebook/react/blob/f33e5790b83dc1ae41b2b7d59d53420e7c8383aa/packages/react/src/ReactCreateRef.js"
  }, React.createElement(_components.code, null, "createRef"), " implementation"), " in the React codebase"), "\n", React.createElement(_components.h3, {
    id: "useref"
  }, "useRef"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "export function useRef(initialValue) {\n  currentlyRenderingFiber = resolveCurrentlyRenderingFiber();\n  workInProgressHook = createWorkInProgressHook();\n  let ref;\n\n  if (workInProgressHook.memoizedState === null) {\n    ref = { current: initialValue };\n    if (__DEV__) {\n      Object.seal(ref);\n    }\n    workInProgressHook.memoizedState = ref;\n  } else {\n    ref = workInProgressHook.memoizedState;\n  }\n  return ref;\n}\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://github.com/facebook/react/blob/8482cbe22d1a421b73db602e1f470c632b09f693/packages/react-reconciler/src/ReactFiberHooks.js#L500-L515"
  }, React.createElement(_components.code, null, "useRef"), " implementation"), " in the React codebase"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
